// Import libraries.
import React from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import { WithWidthProps, withWidth } from "framework/width";

// Import types.
import { MenuItemDefinition } from "components/common/menu/types";

// Import components.
import { Divider } from "@mui/material";
import ImageWrapper from "../ImageWrapper";
import CustomMenu from "components/common/menu";
import NoImageSet from "components/icons/NoImageSet";
import Tooltip from "components/common/Tooltip";
import IconButton from "components/common/button/IconButton";
import ResizableDrawer from "components/common/ResizableDrawer";

// Import icons.
import CloseIcon from "@mui/icons-material/CloseOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreActionsIcon from "@mui/icons-material/MoreVert";

// Import utilities.
import ClipboardUtils from "utils/Clipboard";

interface OWN_PROPS {
    id?: string;

    open: boolean;

    direction?: "left" | "right";

    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    titleIcon?: React.ReactNode;
    actionButtons?: React.ReactNode;
    actions?: React.ReactNode | MenuItemDefinition[];
    copyData?: any;

    resizable?: boolean;
    fullScreen?: boolean;

    persist?: string | null;

    onClose?: () => void;
    onClick?: (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => void;
}

interface PROPS extends React.PropsWithChildren<OWN_PROPS>, WithStyles<typeof styles>, WithWidthProps {}

interface STATE {
    actionsAnchorElement: any;
}
class FlyOutPanel extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        actionsAnchorElement: null,
    };

    onCopyToClipboard = () => {
        const { copyData } = this.props;

        ClipboardUtils.writeText(copyData);
    };

    onClickAway = (_event: MouseEvent | TouchEvent) => {
        // console.log("Flyout Panel - Click Away", this.props.id);
    };

    onClose = (_event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
        if (this.props.onClose) this.props.onClose();
    };

    onClick = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
        if (this.props.onClick) this.props.onClick(event);
    };

    openMenu = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
        this.setState({ actionsAnchorElement: event.currentTarget });
    };

    closeMenu = () => {
        this.setState({ actionsAnchorElement: null });
    };

    renderActions = () => {
        const { classes, copyData, actions, actionButtons } = this.props;

        return (
            <span className={classes.buttonWrapper}>
                {copyData && (
                    <Tooltip alwaysShow arrow title={<Trans>Copy All Data</Trans>}>
                        <IconButton id={"copy-to-clipboard"} onClick={this.onCopyToClipboard}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {actionButtons}
                {actions && Array.isArray(actions) ? (
                    <Tooltip alwaysShow arrow placement={"top"} title={<Trans>Actions</Trans>}>
                        <IconButton id={"more-actions"} style={{ border: "solid 0.125rem" }} aria-label="more actions" onClick={this.openMenu}>
                            <MoreActionsIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    actions
                )}

                {this.props.onClose && (
                    <IconButton id={"dismiss"} type={"neutral"} onClick={this.onClose}>
                        <CloseIcon />
                    </IconButton>
                )}
            </span>
        );
    };

    render() {
        const { classes, id, open, direction = "left", title, subTitle, titleIcon, resizable = true, actions, fullScreen = false, persist, children } = this.props;
        const { actionsAnchorElement } = this.state;

        return (
            <ResizableDrawer
                id={id}
                anchor={direction === "left" ? "right" : "left"}
                open={open}
                minSize={
                    fullScreen
                        ? "100%"
                        : {
                              xs: "100%",
                              sm: 300,
                              md: 420,
                              lg: 540,
                              xl: 540,
                          }
                }
                disableResize={fullScreen || !resizable}
                onClick={this.onClick}
                onClickAway={this.onClickAway}
                persist={persist !== undefined ? (persist === null ? undefined : persist) : "flyout"}
            >
                <div className={classes.header}>
                    <div style={{ display: "flex", flex: "1 1 auto", overflow: "hidden", alignItems: !subTitle ? "center" : "" }}>
                        {titleIcon !== undefined && <ImageWrapper style={{ width: "4em", height: "4em", margin: "0.225em" }} src={titleIcon} fallback={<NoImageSet />} />}
                        <div className={classes.titleWrapper}>
                            <span className={classes.titleBlock}>
                                <span className={classes.title}>{title}</span>
                                {subTitle && this.renderActions()}
                            </span>

                            {subTitle && <span className={classes.subTitle}>{subTitle}</span>}
                        </div>
                    </div>

                    {!subTitle && this.renderActions()}
                </div>

                <Divider />

                <div className={classes.content}>{open && children}</div>

                {actions && Array.isArray(actions) && actions.length > 0 && (
                    <CustomMenu
                        id={id + "-flyout-actions-popover"}
                        anchorElement={actionsAnchorElement}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        open={Boolean(actionsAnchorElement)}
                        onClose={this.closeMenu}
                        menuItems={(actions as MenuItemDefinition[]).map((action) => {
                            return {
                                id: action.id,
                                label: action.label,
                                icon: action.icon,
                                iconPlacement: action.iconPlacement,
                                hidden: action.hidden,
                                disabled: action.disabled,
                                type: action.type,
                                onClick: action.onClick,
                            };
                        })}
                    />
                )}
            </ResizableDrawer>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            borderColor: "var(--secondary-border-color, inherit)",

            overflow: "hidden",

            position: "relative",
        },

        header: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",

            paddingTop: "0.3125em",
            paddingBottom: "0.3125em",
            paddingLeft: "0.625em",
            paddingRight: "0.3125em",
        },

        titleWrapper: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",

            overflow: "hidden",
        },

        titleBlock: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
        },

        title: {
            flex: "1 1 auto",
            overflow: "hidden",
            "& .MuiTypography-root": {
                fontSize: "1.25em",
                fontWeight: "bold",

                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
            },
        },

        subTitle: {
            fontSize: "0.875em",
            fontStyle: "italic",
        },

        buttonWrapper: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",

            overflow: "hidden",
        },

        content: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "hidden",
            overflowY: "auto",

            "& > *": {
                margin: "0.3125em",
            },
        },
    });

export default withWidth()(withStyles(styles)(FlyOutPanel));
