// Import libraries.
import React, { CSSProperties, ForwardedRef } from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";

// Import components.
import { IconButton as MuiIconButton } from "@mui/material";

interface OWN_PROPS {
    id: string;
    type?: "neutral" | "default" | "semantic-positive" | "semantic-negative"; // Defaults to "default"
    color?: string;
    className?: string;
    style?: CSSProperties;
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    tabIndex?: number;
    swallowClickEvents?: boolean;

    [x: string]: any;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

const IconButton = React.forwardRef((props: PROPS, ref: ForwardedRef<any>) => {
    const { classes, id, color, className, style, children, disabled, type, onClick, swallowClickEvents = true, ...otherProps } = props;

    return (
        <MuiIconButton
            id={"icon-button-" + id}
            ref={ref}
            component={"label"}
            color={"inherit"}
            className={classnames({
                [classes.root]: true,
                [type == null ? classes.default : classes[type]]: color == null,
                [classes.customColor]: color != null,
                [className ? className : ""]: true,
            })}
            style={style}
            disabled={disabled}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
                if (onClick && !disabled) {
                    if (swallowClickEvents) {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    if ((event as any).code != null) {
                        const eventAsKeyboardEvent = event as unknown as React.KeyboardEvent;

                        const modifierPresent = eventAsKeyboardEvent.ctrlKey || eventAsKeyboardEvent.altKey || eventAsKeyboardEvent.shiftKey || eventAsKeyboardEvent.metaKey;

                        // Triggered as a result of keyboard event.
                        if (!modifierPresent && ["Space", "Enter"].includes(eventAsKeyboardEvent.code)) {
                            onClick(event);
                        }
                    }

                    if ((event as any).button != null) {
                        // Triggered as a result of mouse event.
                        onClick(event);
                    }
                }
            }}
            {...otherProps}
        >
            {children}
        </MuiIconButton>
    );
});

// Styling for this component.
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            pointerEvents: "all",
            backgroundColor: "transparent",
            color: "inherit",
            borderColor: "transparent",
            "&.Mui-disabled": {
                pointerEvents: "all !important",
                color: "currentColor",
                opacity: 0.5,
                "&:hover": {
                    cursor: "not-allowed",
                },
            },
            "&:hover > .MuiTouchRipple-root": {
                backgroundColor: "currentColor",
                opacity: 0.2,
            },
        },
        neutral: {
            color: "inherit",
        },
        default: {
            color: "var(--icon-button-default-color)",
        },
        "semantic-positive": {
            color: "var(--icon-button-semantic-positive-color)",
        },
        "semantic-negative": {
            color: "var(--icon-button-semantic-negative-color)",
        },

        customColor: (props: OWN_PROPS) => ({
            color: props.color,
        }),
    });

export default withStyles(styles)(IconButton);
